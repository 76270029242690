import { useEffect } from 'react';
import Loader from '../../pages/loader';
import { isNativeApp } from '../utils';
import { useLoggerContext } from './use-logger';
import { useNativeApp } from './use-native-app';

export function AdMobContainer({ onDone, onFail, onShowing }) {
  const logger = useLoggerContext();

  const handleShowAdMob = (event) => {
    logger?.event('adMob', { event: event?.data });
    if (event?.name === 'done') onDone && onDone(event?.data);
    if (event?.name === 'failed') onFail && onFail(event?.data);
    if (event?.name === 'showing') onShowing && onShowing(event?.data);
  };

  const nativeApp = useNativeApp();
  useEffect(() => {
    if (!isNativeApp()) {
      onFail && onFail('AdMob is not supported');
      return;
    }
    const unsubscibe = nativeApp?.addAdEventListener(
      'onShowAdMob',
      handleShowAdMob
    );
    nativeApp?.showAdMob();

    return () => {
      unsubscibe && unsubscibe();
    };
  }, []);

  return (
    <>
      <Loader overlay />
    </>
  );
}
