import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFbPixel } from '../shared/hooks/use-fb-pixel';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { useNativeApp } from '../shared/hooks/use-native-app';
import { useSoundContext } from '../shared/hooks/use-sound';
import { isInAppBrowser, isAndroid, isNativeApp } from '../shared/utils';
import { loadPersistentState } from '../shared/utils/persist';
import { InstallDialog } from './install';

export default function Landing() {
  const logger = useLoggerContext();
  const navigate = useNavigate();
  const { track } = useFbPixel();
  const { normalClick, html5Click } = useSoundContext();
  const [isCanvasInited, setIsCanvasInited] = useState(false);
  const [isInstallShowing, setIsInstallShowing] = useState(false);

  useEffect(() => {
    window.Telegram?.WebApp?.disableVerticalSwipes();

    // Initialising the canvas
    const canvas = document.querySelector('canvas');
    const ctx = canvas && canvas.getContext('2d');

    // Setting the width and height of the canvas
    canvas.width = canvas.clientWidth; // window.innerWidth;
    canvas.height = canvas.clientHeight; //window.innerHeight;

    // Setting up the letters
    let letters = '1234567890';
    letters = letters.split('');

    // Setting up the columns
    var fontSize = 13,
      columns = canvas.clientWidth / fontSize;

    // Setting up the drops
    var drops = [];
    for (var i = 0; i < columns; i++) {
      //      drops[i] = 1;
      drops[i] = (Math.random() * canvas.clientHeight) / fontSize;
    }

    // Setting up the draw function
    function draw() {
      ctx.fillStyle = 'rgba(33, 43, 52, 0.7)'; //'rgba(0, 0, 0, .1)'; //'#212b34';
      ctx.fillRect(0, 0, canvas.clientWidth, canvas.clientHeight);

      for (var i = 0; i < drops.length; i++) {
        var text = letters[Math.floor(Math.random() * letters.length)];
        ctx.fillStyle = '#5bb576'; //'#0f0';
        ctx.font = `normal 100 ${fontSize}px sans-serif`;
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

        drops[i]--;
        if (drops[i] <= 0 && Math.random() > 0.5) {
          drops[i] = drops[i] = canvas.clientHeight / fontSize;
        }
      }
    }

    if (!isCanvasInited) {
      // Loop the animation
      setInterval(draw, 200);
      setIsCanvasInited(true);
    }

    if (isAndroid() && isInAppBrowser()) {
      setIsInstallShowing(true);
    }

    //Heartbeat
    /*
     const logHeartbeat = (next = 2000) => {
      if (next <= 18000)
        setTimeout(() => {
          logger?.event('landing', {
            waiting: Math.round(next / 1000),
          });
          logHeartbeat(next + 4000);
        }, next);
    };
    logHeartbeat();
    */
  }, []);

  const nativeApp = useNativeApp();
  return (
    <div
      className="landing"
      onTouchStart={(e) => {
        if (e.touches['3']) {
          //4 fingers touch
          if (isNativeApp() && process.env.NODE_ENV === 'production') {
            const { debug_native_app_href } = loadPersistentState('settings');
            window.location.href = !debug_native_app_href
              ? window.location.href
              : debug_native_app_href;
          }
        }
      }}
      onDoubleClick={(e) => {
        if (process.env.NODE_ENV !== 'production') {
          setIsInstallShowing(true);
          //nativeApp.alert('Hi from pwa');
          //nativeApp.vibrate(2000);
          //nativeApp.haptics('warning');
          //nativeApp.showAdMob();
        }
      }}
    >
      <div className="text-center landing-container">
        <h1>BOXO</h1>
        <div>
          <small>{t('Box Options Game')}</small>
        </div>
        <img src="/img/logo.svg" alt="boxo-logo" />
        <div className="text-default">{t('Guess the next BITCOIN price')}</div>
        <div className="text-muted">
          <small>Binance • Coinbase • Gate • OKx • Bybit</small>
        </div>
        <div
          className="btn btn-primary btn-flashing"
          role="button"
          onClick={() => {
            const canvas = document.querySelector('canvas');
            logger?.event('click', {
              target: 'PlayNow',
              canvas: { w: canvas.clientWidth, h: canvas.clientHeight },
            });
            track('ClickPlayNow');
            html5Click();
            normalClick();
            nativeApp.haptics('success');
            navigate('/app', { replace: true });
          }}
        >
          {t('PLAY NOW')}
        </div>
        {process.env.REACT_APP_VERSION && (
          <div className="text-muted" style={{ fontSize: '12px' }}>
            Version: {process.env.REACT_APP_VERSION}
            {process.env.NODE_ENV !== 'production' ? '.dev' : ''}
          </div>
        )}
      </div>

      <InstallDialog
        logger={logger}
        show={isInstallShowing}
        onClose={() => setIsInstallShowing(false)}
      />

      <canvas />
    </div>
  );
}
