import { useMemo } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAndroid, isIos } from '../utils';
import { loadPersistentState } from '../utils/persist';
import { useLoggerContext } from './use-logger';

const NativeApp = createContext();

export function NativeAppContext({ children }) {
  const NativeAppProps = useNativeApp();
  const persistedNativeAppProps = useMemo(
    () => NativeAppProps,
    [NativeAppProps]
  );
  return (
    <NativeApp.Provider value={persistedNativeAppProps}>
      {children}
    </NativeApp.Provider>
  );
}
export const useNativeAppContext = () => useContext(NativeApp);

export function useNativeApp() {
  const navigate = useNavigate();
  const logger = useLoggerContext();

  function handleNativeAppMessage(msg) {
    try {
      const data = JSON.parse(msg.data);
      switch (data?.method) {
        case 'onShowAdMob':
          showAdMobEventListener?.current &&
            showAdMobEventListener.current(data?.event);
          break;
        case 'navigate':
          setTimeout(() => {
            const location = data?.location?.url
              ? data.location.url.replace('https://boxo.trade', '') //TODO: remove it
              : data?.location?.replace('https://boxo.trade', '');
            navigate(location, { replace: true });
          }, 400);
          break;
        case 'log':
          const name = data?.event?.name;
          const eventData = data?.event?.data;
          logger?.event(name, eventData);
          alert(
            `Logger event "${name}" with data: ${JSON.stringify(eventData)}`
          );
          break;
        case 'alert':
          alert(data?.message);
          break;
        default:
          if (process.env.NODE_ENV !== 'production')
            alert('Native app calls unknown method: ' + data?.method);
      }
    } catch (_) {}
  }

  useEffect(() => {
    /*     const messageListener = isIos()
      ? window.addEventListener('message', (msg) => handleNativeAppMessage(msg))
      : isAndroid()
      ? document.addEventListener('message', (msg) =>
          handleNativeAppMessage(msg)
        )
      : null;
 */
    const messageListener = window.addEventListener(
      'message',
      (msg) => handleNativeAppMessage(msg),
      true
    );
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  function postData(data) {
    window.ReactNativeWebView?.postMessage(JSON.stringify(data));
  }

  function alert(message) {
    postData({ method: 'alert', message });
  }
  function vibrate(vibration) {
    postData({ method: 'vibrate', vibration });
  }
  function showAdMob() {
    const settings = loadPersistentState('settings');
    postData({
      method: 'showAdMob',
      adType: settings?.adMob?.adType
        ? settings.adMob.adType
        : 'rewardedInterstitial',
      adUnitId: settings?.adMob?.adUnitId
        ? settings.adMob.adUnitId
        : 'ca-app-pub-9242516157588064/2017226690',
    });
  }
  function haptics(type) {
    postData({ method: 'haptics', type });
  }

  const showAdMobEventListener = useRef();

  function addAdEventListener(event, callback) {
    if (event === 'onShowAdMob') {
      showAdMobEventListener.current = callback;
      return () => (showAdMobEventListener.current = null);
    }
  }

  return { alert, vibrate, haptics, showAdMob, addAdEventListener };
}
