import React, {
  useEffect,
  useRef,
  useReducer,
  useState,
  useCallback,
} from 'react';
import { t } from 'i18next';
import { useInterval } from '../shared/hooks/use-interval';
import { userOnboardingMachine } from '../shared/machines';
import { useMachine } from '@xstate/react';
import { throttle } from 'lodash';
import {
  ResponsiveContainer,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  Label,
  XAxis,
  YAxis,
  ScatterChart,
  Scatter,
} from 'recharts';
import {
  isAndroid,
  isIos,
  isNativeApp,
  isPwa,
  priceFmt,
  priceFmtThousands,
} from '../shared/utils';
import { AnimatedDot, AnimatedCongrats } from '../shared/animation';
import useWebSocket from 'react-use-websocket';
import { v4 as uuid } from 'uuid';
import { useUserContext } from '../shared/hooks/use-user';
import {
  BackgoundLight,
  BetControl,
  BlurPanel,
  DebugControl,
  ProgressBar,
  SoundControl,
  SuperBox,
  SvgStyles,
} from '../components/terminal';
import { DepositDialog } from './deposit';
import useBlockchainDeposit from '../shared/hooks/use-blockchain';
import {
  toast,
  winToast,
  clearToasts,
  WinToastContainer,
  ToastContainer,
} from '../shared/toast';
import { CountUpDown, changeType } from '../shared/countupdown';
import { WithdrawDialog } from './withdraw';
import { fetchPrices } from '../shared/api/price';
import { useBonuses } from '../shared/hooks/use-bonus';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Auth from './auth';
import NotFound from './404';
import Settings from './settings/settings';
import PendingRedirect from './pending';
import { Balance } from './balance';
import Loader from './loader';
import { useFbPixel } from '../shared/hooks/use-fb-pixel';
import { useSoundContext } from '../shared/hooks/use-sound';
import {
  boxSelectionMachine,
  boxesMachine,
  boxStatus,
} from '../shared/machines/terminal';
import { animatedCongratsMachine } from '../shared/machines/animation';
import useEmulatorPrice from '../emulator/emulator';
import { BonusListDialog } from './bonus';
import { sendBet } from '../shared/api/account';
import { Leaderboard, LeagueStatus } from './leaderboard';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { useUpdaterContext } from '../shared/hooks/use-updater';
import { SystemDialog } from '../shared/dialogs';
import { useWssPrices } from '../shared/hooks/use-wssPrices';
import { useNativeApp } from '../shared/hooks/use-native-app';

const isEmulator = false;
const usePrices = isEmulator ? useEmulatorPrice : useWssPrices;

const lootBoxTypes = { grade0: 1, grade1: 3, grade2: 6 };

export default function Terminal() {
  const logger = useLoggerContext();
  const [debugPausePrice, setDebugPausePrice] = useState(false);

  const navigate = useNavigate();

  const {
    normalClick: clickSound,
    wetClick: wetClickSound,
    levelUp: levelUpSound,
    hits: hitsSound,
    win: winSound,
    notification: notificationSound,
  } = useSoundContext();

  const {
    initUser,
    resetUser,
    user,
    balance,
    winBalance,
    setBalance,
    setWinBalance,
    isLoading,
    setOnUserLogout,
    isLogged,
  } = useUserContext();

  const { updateApp, updateIsRequired } = useUpdaterContext();

  const onLogout = async (sender) => {
    setOnUserLogout(null);
    resetUser(sender);
    setTimeout(() => {
      navigate('/', { replace: true });
    }, 400);
  };

  useEffect(() => {
    const init = async () => {
      if (isLogged) await initUser(user?.email);
      setOnUserLogout(onLogout);
    };
    init();
  }, []);

  const refWinBalance = useRef();
  refWinBalance.current = winBalance;

  const refBalance = useRef();
  refBalance.current = balance;

  const {
    getActualBonuses,
    getActualChallengeBonus,
    getActualRecoveryBonus,
    getActualWelcomeBonus,
    isLoading: isBonusesLoading,
    fetchData: fetchBonusData,
  } = useBonuses();
  const [bonuses, setBonuses] = useState([]);
  const [challengeBonus, setChallengeBonus] = useState(null);

  useEffect(() => {
    if (isBonusesLoading) return;
    const newBonuses = getActualBonuses();
    const newChallengeBonus = getActualChallengeBonus();
    setBonuses(newBonuses);
    setChallengeBonus(newChallengeBonus);

    sendUserOnboarding({
      type: 'RESET',
      payload: {
        winsCounter: newChallengeBonus?.progress,
        winsCounterLimit: newChallengeBonus?.params?.limit,
      },
    });
  }, [user?.email, isBonusesLoading]);

  const [userOnboarding, sendUserOnboarding, service] = useMachine(
    userOnboardingMachine,
    {
      actions: {
        notifyOnLimit: (e, c) => {
          if (!user?.email) {
            setTimeout(() => {
              if (location.pathname.replace(/\/+$/, '') === '/app') {
                navigate('./bonus/no-auth', { replace: true });
                clearToasts();
                sendUserOnboarding({ type: 'RESET' });
              }
            }, 100);
          }
        },
      },
    }
  );

  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    setIsMobile(isIos() || isAndroid() || isNativeApp());
    setLastPriceUpdate(Date.now());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Animated coins
  const pendingAnimation = useRef(0);
  const onAnimatioCompete = (count) => {
    pendingAnimation.current = Math.max(0, pendingAnimation.current - count);
  };

  const [isBoxesGridShown, setIsBoxesGridShown] = useState(true);

  const [animatedCongratsState, sendAnimatedCongrats] = useMachine(
    animatedCongratsMachine
  );

  //time
  const boxLines = 5; //5..7
  const boxLinesArray = Array(boxLines).fill(0);
  const [boxColumns, setBoxColumns] = useState(9);
  const boxColumnsArray = Array(boxColumns).fill(0);

  const boxesOffset = 5000; //Time interval between price and 1st line of boxes
  const boxDuration = 2000; //height in msec
  const timeForBoxes = boxDuration * boxLines + boxesOffset;
  const [timeHistory, setTimeHistory] = useState(15 * 1000);
  const [timeDomain, setTimeDomain] = useState({
    min: -timeHistory,
    max: timeForBoxes,
  });
  const [time, setTime] = useState(0);

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [lastPriceUpdate, setLastPriceUpdate] = useState();
  const [lastUpdate, setLastUpdate] = useState();

  //price
  const [priceDomain, setPriceDomain] = useState({ min: 0, max: 10 });
  const [price, setPrice] = useState(0);
  //price data
  const [data, setData] = useState([]); //[{time:-1, price}, {time:0, price}]
  const [recentPricesData, setRecentPricesData] = useState({ used: false });

  useEffect(() => {
    if (data.length !== 2) {
      return;
    }
    const start = data[0].time - timeHistory;
    const end = data[0].time;
    fetchPrices(start, end).then((prices) => {
      setRecentPricesData({ prices, used: false });
    });
  }, [data, timeHistory]);

  useEffect(() => {
    if (!recentPricesData.prices || recentPricesData.used) {
      return;
    }
    setRecentPricesData({ used: true });

    const convertPrices = (prices) =>
      !prices || !Array.isArray(prices)
        ? []
        : prices
            .map((price, i, prices) => {
              const prevPrice =
                i > 0 && prices.length > 0 ? prices[i - 1] : price;
              return [
                {
                  time: prevPrice.timestamp,
                  price: price.value,
                },
                {
                  time: price.timestamp,
                  price: price.value,
                },
              ];
            })
            .flat();

    updateData(
      data,
      convertPrices(recentPricesData.prices),
      timeHistory,
      setData
    );
  }, [recentPricesData, setData, data, timeHistory]);

  //bet
  const [bet, setBet] = useState(1);

  //grid
  const [boxesGrid, setBoxesGrid] = useState();
  const [boxesGridMismatch, setBoxesGridMismatch] = useState(0); //mismatch beween 1st line of boxes and real grid position in pixels

  const [terminalSize, setTerminalSize] = useState({ height: 800, width: 500 });
  const [centralPoint, setCentralPoint] = useState({ x: 0, y: 0 });
  const boxTerminalRef = useRef();

  const onDeposit = useCallback(
    async (income, asset, network, depositAddress) => {
      const newBalance = balance + income * 1000;

      setBalance(newBalance);
      toast(
        t(`Deposited {{amount}} {{asset}}`, {
          amount: income.toFixed(2),
          asset,
        }),
        {
          delay: 1500,
        }
      );

      logger?.event('deposit', {
        balance: newBalance,
        winBalance: winBalance,
        amount: income,
        asset,
        network,
        address: depositAddress,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [balance]
  );
  const [assets, history] = useBlockchainDeposit(user.email, onDeposit, logger);

  //boxes
  const [boxSize, setBoxSize] = useState(0); //width in dollars
  const [boxSizePx, setBoxSizePx] = useState(0); //width in px

  const [recoveryBonusToastTime, setRecoveryBonusToastTime] = useState(0);

  const [boxesState, boxesSend] = useMachine(boxesMachine, {
    state: {
      ...boxesMachine.initialState,
      context: { ...boxesMachine.initialState.context, timeHistory },
    },
    actions: {
      notifyAdded: () => {
        nativeApp.haptics('selection');
        wetClickSound && wetClickSound();
      },
      notifyUpdate: (context, event) => {
        const prize = event.data.prize;
        const pendingBoxesCount = event.data.count.pending;
        const executedCount = event.data.count.executed;
        const maxMultiplier = event.data.count.maxMultiplier;
        const maxPrize = event.data.count.maxPrize;

        if (pendingBoxesCount === 0) setIsBoxesGridShown(true);

        if (
          !context.lastExecutedTime ||
          Date.now() - context.lastExecutedTime > boxTimeSize
        )
          context?.getCloser && hitsSound(context?.getCloser);

        if (challengeBonus && challengeBonus.expired <= Date.now()) {
          updateBonusData();
        } else if (pendingBoxesCount > 0 || executedCount > 0) {
          sendUserOnboarding({
            type: 'WIN_OR_LOSE',
            payload: {
              prize,
              wins: executedCount,
              ...event.data.count,
            },
          });
        }

        const recoveryBonus = getActualRecoveryBonus();
        if (
          prize <= 0 &&
          pendingBoxesCount === 0 &&
          recoveryBonus &&
          recoveryBonus.availableAt - Date.now() < 0 &&
          balance < recoveryBonus.params?.minBalance &&
          (location.pathname.replace(/\/+$/, '') === '/app' ||
            location.pathname.replace(/\/+$/, '') === '/app/bonus')
        ) {
          if (
            //Date.now() - recoveryBonus.lastSeenAt > 5 * 60 * 1000 &&
            Date.now() - recoveryBonusToastTime >
            1 * 30 * 1000
          ) {
            setRecoveryBonusToastTime(Date.now());
            notificationSound();
            toast(t(`Claim recovery bonus`), {
              //delay: 500,
              onClick: () => {
                navigate('./bonus/recovery', {
                  replace: true,
                  state: location.pathname,
                });
              },
              icon: () => (
                <img width="32" alt="" src="/img/red-heart-flat.svg" />
              ),
            });
          }
        }

        if (prize > 0) {
          const newWinBalance = refWinBalance.current + prize;
          const newBalance = refBalance.current + prize;
          setWinBalance(newWinBalance);
          setBalance(newBalance);

          //Sound
          if (maxMultiplier >= 1.5) winSound(2);
          else if (maxMultiplier >= 1.3) winSound(1);
          else winSound(0);

          nativeApp.haptics('heavy');

          //Visual
          sendAnimatedCongrats('ADD', {
            payload: { text: `× ${priceFmt(maxMultiplier, 1, 1)}` },
          });

          const congratMessage =
            maxMultiplier >= 40
              ? t(`Phenomenal!`)
              : maxMultiplier >= 20
              ? t(`Legendary!`)
              : maxMultiplier >= 10
              ? t(`Incredible!`)
              : maxMultiplier >= 8
              ? t(`Epic!`)
              : maxMultiplier >= 7
              ? t(`Outstanding!`)
              : maxMultiplier >= 5
              ? t(`Super!`)
              : '';

          congratMessage &&
            location.pathname.replace(/\/+$/, '') === '/app' &&
            winToast(congratMessage, {
              winMultiplier: maxMultiplier,
              winAmount: maxPrize,
              onOpen: () => {
                levelUpSound();
              },
            });

          const firstWin = 10; //userOnboarding.context.wins ? 0 : 10;
          pendingAnimation.current =
            pendingAnimation.current +
            Math.max(1, prize - executedCount) +
            firstWin;

          !isLogged &&
            logger?.event('win', {
              winCount: Number(executedCount?.toFixed(0)),
              amount: Number(prize?.toFixed(2)),
              multiplier: maxMultiplier,
              bigWin: !!congratMessage,
              balance: Number((balance + prize)?.toFixed(2)),
              winBalance: Number(newWinBalance?.toFixed(2)),
            });
        }
      },
    },
  });

  const nativeApp = useNativeApp();

  const [boxSelectionState, boxSelectionSend] = useMachine(
    boxSelectionMachine,
    {
      services: {
        submitBoxes: (context) =>
          new Promise(async (resolve, reject) => {
            try {
              const boxes = await submitBoxes(context.selection);
              resolve({ boxes });
            } catch {
              reject();
            }
          }),
      },
      actions: {
        notifyAdding: () => {
          clickSound && clickSound();
          nativeApp.haptics('selection');
        },

        notifyError: () => {
          //no email
          const pendingCount = boxesState?.context?.pendingCount;
          if (user.email === '') {
            setTimeout(() => {
              clearToasts();
              navigate('./bonus/no-auth', { replace: true });
            }, 300);
          }
          //email is not empty
          if (user.email !== '') {
            if (balance < 1) {
              const b = getActualWelcomeBonus();
              if (b) {
                navigate('./bonus/welcome', { replace: true });
              } else {
                toast(t(`Insufficient balance`));
                navigate('./p/500/balance', { replace: true });
              }
            } /* else {
              if (pendingCount === 0 && winBalance > 0) {
                navigate('./p/300/withdraw', { replace: true });
              }
            } */
          }
        },

        notifySubmission: (_, event) => {
          const boxes = event.data.boxes;
          if (boxes.length === 0) return;
          boxesSend('ADD', { payload: { boxes } });
          const newBalance = balance - boxes.length * bet;
          setBalance(newBalance);
          //setIsBoxesGridShown(false);

          userOnboarding.context.bets === 0 && track('FirstBox'); //userOnboarding.matches('newcomer')

          sendUserOnboarding({
            type: 'BET',
            payload: { boxes: boxes.length },
          });

          !isLogged &&
            logger?.event('box', {
              bet,
              count: boxes.length,
              balance: Number(newBalance?.toFixed(2)),
              //amount: totalPrize,
            });
        },
      },
    }
  );

  function getBoxInGridByXY(price, time) {
    return (
      price &&
      boxesGrid &&
      boxesGrid.find(
        (box) =>
          box.x1 <= price && price <= box.x2 && box.y1 <= time && time <= box.y2
      )
    );
  }

  async function submitBoxes(uniqueSelectedBoxes) {
    const newBoxes = uniqueSelectedBoxes
      .map((box) => {
        const x = price + (box.dx1 + box.dx2) / 2;
        const y = time + (box.dy1 + box.dy2) / 2;
        const gridBox = getBoxInGridByXY(x, y);
        const loseProbability = 1 - 1 / (1 + gridBox.prize); //eg if prize=2 then p=0.7
        const lootBox =
          gridBox.prize > 1.1 &&
          Math.random() <= (loseProbability ^ 2) / (10 * 5 * Math.sqrt(bet)) //eg p=0.70 if pLootBox=0.0049 //0.98%
            ? lootBoxTypes.grade1
            : gridBox.prize > 1.1 &&
              Math.random() <=
                (loseProbability ^ 2) / (10 * 25 * Math.sqrt(bet)) //eg if p=0.70 pLootBox=0.0049 * 2  = 0.098%
            ? lootBoxTypes.grade2
            : lootBoxTypes.grade0;
        const prize = lootBox * gridBox.prize * bet;
        return {
          ...gridBox,
          bet: box.bet,
          status: boxStatus.pending,
          lootBox,
          prize,
          originalPrize: gridBox.originalPrize,
          multiplier: !user?.email ? gridBox.prize * lootBox : gridBox.prize,
          id: uuid(),
        };
      })
      .filter((b) => b.multiplier >= 1);
    const sufficientBoxesCount = (balance / bet) >> 0;
    if (newBoxes.length > 0 && sufficientBoxesCount <= 0)
      throw new Error('Not enough balance');
    const sufficientBoxes = newBoxes.slice(0, sufficientBoxesCount);

    if (user.email === '') return sufficientBoxes;
    if (sufficientBoxes.length === 0) return [];
    else {
      const data = await sendBet(time, bet, sufficientBoxes);
      const { modifiedPrizes, error } = data;
      if (error) {
        logger.event('error', { msg: `Send box error: ${error}` });
        if (error === 'low balance') navigate('/');
        console.error('Send box error', error);
        return [];
      }
      const modifiedBoxes = sufficientBoxes.map((sb, i) => {
        const modifiedPrize =
          modifiedPrizes?.find((mp) => mp.pos === i)?.prize * 1;
        return {
          ...sb,
          lootBox: modifiedPrize ? lootBoxTypes.grade2 : lootBoxTypes.grade0,
          prize: modifiedPrize ? modifiedPrize * bet : sb.originalPrize * bet, //!
          originalPrize: modifiedPrize ? modifiedPrize : sb.originalPrize,
          multiplier: modifiedPrize ? modifiedPrize : sb.multiplier,
        };
      });
      return modifiedBoxes;
    }
  }

  const addSelectedBox = throttle((box) => {
    if (!box) return;
    const x = box.x1 + boxSize / 2;
    const y = box.y1;
    const newBox = {
      ...box,
      status: boxStatus.pending,
      x,
      y,
      dx1: box.x1 - price,
      dx2: box.x2 - price,
      dy1: box.y1 - time,
      dy2: box.y2 - time,
      bet,
    };
    boxSelectionSend({ type: 'ADD', payload: { box: newBox } });
  }, 10);

  const location = useLocation();

  const [boxTimeSize, setBoxTimeSize] = useState();

  function onNewPrice(event) {
    //logger.logData(event); //saves data to axios for using it in emulator
    if (debugPausePrice) return;
    setLastPriceUpdate(Date.now());

    //price
    const newPrice = event.price.value;
    setPrice(newPrice);

    const newBoxSize = event.boxes ? event.boxes.config.price_size : boxSize;
    setBoxSize(newBoxSize); //width in dollars

    //adjust range to maintain aspect ratio of the boxes
    const numberOfBoxes = terminalSize.width > 500 ? 11 : 9;
    setBoxColumns(numberOfBoxes);
    const priceRange = (numberOfBoxes * newBoxSize) / 2;
    const boxSizePx = terminalSize.width / numberOfBoxes; //Math.floor(terminalSize.width / numberOfBoxes);
    //const rest = terminalSize.width - (numberOfBoxes * boxSizePx) / 2;
    setBoxSizePx(boxSizePx);
    const timeHistory = Math.round(
      (boxDuration * terminalSize.height) / boxSizePx - timeForBoxes
    );
    setTimeHistory(timeHistory);

    setCentralPoint({
      x: terminalSize.width / 2,
      y: (terminalSize.height * timeHistory) / (timeHistory + timeForBoxes),
    });

    //const boxesTop = (terminalSize.height * timeHistory) / (timeHistory + timeForBoxes)

    setPriceDomain({
      min: newPrice - priceRange,
      max: newPrice + priceRange,
    });

    //Time
    const newTime = event.price.timestamp;
    const oldTime = time || newTime;
    setTime(newTime);

    const minTime = newTime - timeHistory;
    const maxTime = newTime + timeForBoxes;
    setTimeDomain({
      min: minTime,
      max: maxTime,
    });

    boxesSend('UPDATE', { payload: { price: newPrice, time: newTime } });

    //boxes grid
    const newBoxesGrid = [];

    if (!event.boxes) logger?.event('error', { msg: 'missing boxes' });
    else {
      const { config, prizes } = event.boxes;
      if (config.time_size !== boxTimeSize) setBoxTimeSize(config.time_size);

      const newMismatch =
        ((boxesOffset - config.delay) / config.time_size) * boxSizePx;
      setBoxesGridMismatch(newMismatch);

      let countBoxes = 0;
      for (let t = 0; t < Math.min(boxLines, config.time_count); t += 1) {
        for (let p = 0; p < config.price_count; p += 1) {
          const y1 = newTime + config.delay + t * config.time_size;
          const y2 = y1 + config.time_size;
          const x1 =
            newPrice - (newBoxSize * config.price_count) / 2.0 + p * newBoxSize;
          const x2 = x1 + config.price_size;
          const originalPrize = prizes[t][p];
          //const prize = Math.floor(originalPrize * 10) / 10; //TODO - server rounding
          const newPrize =
            Math.floor(originalPrize * 10) / 10 !== 1
              ? originalPrize
              : originalPrize * (1 + 0.1 * Math.floor(Math.random() + 0.15));

          const oldPrize =
            Math.round(
              boxesGrid &&
                boxesGrid.length &&
                //boxesGrid[p * config.time_count + t].prize * 10
                boxesGrid[countBoxes]?.prize * 10
            ) / 10;

          const isHidden =
            x1 < newPrice - priceRange ||
            x2 > newPrice + priceRange ||
            y2 > maxTime;
          if (!isHidden) {
            const box = {
              id: countBoxes,
              x1,
              x2,
              y1,
              y2,
              prize: newPrize,
              originalPrize,
              isPrizeUpdated: oldPrize !== Math.round(newPrize * 10) / 10,
            };
            newBoxesGrid.push(box);
            countBoxes++;
          }
        }
      }
    }
    setBoxesGrid(newBoxesGrid);

    //history
    updateData(
      data,
      [
        {
          time: oldTime,
          price: newPrice,
        },
        {
          time: newTime,
          price: newPrice,
        },
      ],
      timeHistory,
      setData
    );
  }

  useInterval(() => {
    if (debugPausePrice) return;

    //mitigate viewport zoom on double tap on ios devices
    const viewportmeta = document.querySelector('meta[name=viewport]');
    viewportmeta &&
      viewportmeta.setAttribute(
        'content',
        'initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0'
      );

    //mitigate remaining selection issue
    if (pendingAnimation.current > 0) forceUpdate();

    //mitigate page freezing in safari
    setLastUpdate(Date.now());
    if (!debugPausePrice && lastUpdate - lastPriceUpdate > 3000) {
      setPrice(undefined);
    }
  }, 300);

  usePrices(onNewPrice);

  const onLogin = async (email, pathname) => {
    boxesSend('RESET');
    initUser(email).then(() => {
      navigate(pathname ? pathname : './', { replace: true });
    });
  };

  const { track } = useFbPixel();

  const onClaimBonusError = async (bonus, error) => {
    if (bonus.typeId === 'ChallengeBonus') {
      sendUserOnboarding({
        type: 'RESET',
        payload: { winsCounter: 0 },
      });
    }
    toast(
      t(`Can not claim bonus: {{error}}`, {
        error,
      })
    );
    //navigate('/app', { replace: true });
    logger?.event('error', { msg: 'Can not claim bonus', error });
  };

  const updateBonusData = () => {
    fetchBonusData((newBonuses) => {
      const newChallengeBonus = newBonuses.find(
        (b) => b.typeId === 'ChallengeBonus' && b.expired > Date.now()
      );
      if (newChallengeBonus) {
        sendUserOnboarding({
          type: 'RESET',
          payload: {
            winsCounter: newChallengeBonus?.progress,
            winsCounterLimit: newChallengeBonus?.params?.limit,
          },
        });
      }
    });
  };

  const onClaimBonus = async (bonus) => {
    const bonusAmount = Number(bonus.claimedAmount * 1);
    const newBalance = Number(balance * 1) + bonusAmount;
    setBalance(newBalance);

    logger?.event('bonus', {
      amount: bonusAmount,
      balance: newBalance,
      descr: bonus.typeId,
    });

    if (bonus.typeId === 'ChallengeBonus') {
      updateBonusData();
      navigate('./league', { replace: true });
      return;
    }
    if (bonus.typeId === 'WelcomeBonusFreeBoxes') {
      navigate('/app/bonus/push', { replace: true });
      return;
    }
    navigate('/app', { replace: true });
  };

  const onRegister = (email) => {
    initUser(email).then(() => {
      track('Registered');
    });
  };

  const topMenuHeight = 70;
  const bottomMenuHeight = isNativeApp() || isPwa() ? (isIos() ? 80 : 60) : 0;

  return (
    <>
      <SvgStyles />
      <ToastContainer />
      <WinToastContainer />

      <Routes>
        <Route
          path="/" //Components that shown only at /app
          element={
            <div>
              <SystemDialog
                title={t('Update app')}
                show={updateIsRequired}
                actions={[`OK`]}
                message={t('New version is available')}
                onAction={() => updateApp()}
              />
            </div>
          }
        />
        <Route path="/p/:delay/*" element={<PendingRedirect />} />
        <Route path="/*" element={<NotFound />} />
        {user.email && (
          <>
            <Route
              path="/balance/*"
              element={
                <Balance balance={balance} logger={logger} email={user.email} />
              }
            />
            <Route
              path="/deposit/*"
              element={
                <DepositDialog
                  email={user?.email}
                  assets={assets}
                  history={history}
                  balance={balance}
                  winBalance={winBalance}
                  logger={logger}
                />
              }
            />
            <Route
              path="/leaderboard/*"
              element={
                <Leaderboard
                  email={user.email}
                  winBalance={winBalance}
                  logger={logger}
                />
              }
            />
            <Route
              path="/league"
              element={
                <LeagueStatus
                  email={user.email}
                  winBalance={winBalance}
                  logger={logger}
                />
              }
            />

            <Route
              path="/withdraw/*"
              element={
                <WithdrawDialog
                  email={user.email}
                  winBalance={winBalance}
                  logger={logger}
                  onReset={() => {
                    //ReseetWinBalance();
                  }}
                  onWithdraw={(withdrawAmount, withdrawalAddress) => {
                    const newWinBalance = winBalance - withdrawAmount * 100;
                    setWinBalance(newWinBalance);

                    toast(
                      t(`Withdrawal requested {{amount}} USDT`, {
                        amount: withdrawAmount,
                      }),
                      {
                        delay: 1000,
                      }
                    );

                    logger?.event('withdraw', {
                      amount: withdrawAmount,
                      asset: 'USDT',
                      address: withdrawalAddress,
                      balance: balance,
                    });

                    if (
                      process.env.NODE_ENV !== 'production' &&
                      winBalance - withdrawAmount < 0
                    ) {
                      setBalance(999);
                      setWinBalance(0);
                    }
                  }}
                />
              }
            />
            <Route
              path="/settings/*"
              element={
                <Settings
                  pendingBoxes={boxesState.context.pendingCount}
                  email={user.email}
                  logger={logger}
                  onLogout={onLogout}
                />
              }
            />
          </>
        )}
        {!user.email && (
          <>
            <Route
              path="/settings/*"
              element={<Loader overlay redirect="/app/auth/login" />}
            />
            <Route
              path="/balance/*"
              element={<Loader overlay redirect="/app/auth/login" />}
            />
            <Route
              path="/deposit/*"
              element={<Loader overlay redirect="/app/auth/login" />}
            />
            <Route
              path="/withdraw/*"
              element={<Loader overlay redirect="/app/auth/login" />}
            />
            <Route
              path="/bonus"
              element={<Loader overlay redirect="/app/auth/login" />}
            />
          </>
        )}
        <Route
          path="/auth/*"
          element={
            <Auth
              email={user?.email}
              logger={logger}
              onLogin={onLogin}
              onRegister={onRegister}
            />
          }
        />
        <Route
          path="/bonus/*"
          element={
            <BonusListDialog
              email={user?.email}
              logger={logger}
              onClaimBonus={onClaimBonus}
              onClaimBonusError={onClaimBonusError}
              onLogin={onLogin}
            />
          }
        />
      </Routes>

      <div
        className="container pt-3 pb-3"
        style={{
          height: `${topMenuHeight}px`,
          width: '100%',
        }}
      >
        <div className="row">
          <div className="col-4">
            <div className="text-start text-default">
              Bitcoin
              <img
                src="/img/btc.svg"
                alt=""
                style={{
                  width: '18px',
                  marginTop: '-3px',
                  marginLeft: '4px',
                  marginRight: '4px',
                  opacity: 0.9,
                }}
              />
            </div>
            <div className="text-start text-muted lh-1">
              <small>{t('Asset price')}</small>
            </div>
          </div>

          <div className="col-4">
            <div
              className=""
              role="button"
              onClick={() => {
                if (user.email === '') {
                  navigate('./auth/sign-up', { replace: true });
                } else {
                  if (winBalance > 0 || process.env.NODE_ENV !== 'production')
                    navigate('./league', { replace: true });
                }
              }}
            >
              <div
                className="text-center glow-small text-primary-accent text-monospace"
                style={{ fontWeight: 400 }}
              >
                {(user?.email && winBalance) || userOnboarding.context.prize ? (
                  <CountUpDown
                    duration={2}
                    decimals={bet < 10 ? 1 : 0} //{winBalance > 1000 ? 0 : 1}
                    value={
                      !user?.email ? userOnboarding.context.prize : winBalance
                    }
                    animate={changeType.up}
                  />
                ) : (
                  '-'
                )}
              </div>
              <div className="text-center text-muted lh-1">
                <small>
                  {t('Win')}
                  {
                    //winBalance > 0 && <span className="btn-withdraw-dropdown" />
                  }
                </small>
              </div>
            </div>
          </div>

          <div className="col-4">
            <div
              className=""
              role="button"
              onClick={() => {
                if (user.email === '') {
                  navigate('./auth/sign-up', { replace: true });
                } else {
                  navigate('./balance', { replace: true });
                }
              }}
            >
              <div className="row flex-nowrap">
                <div className="col-10">
                  <div
                    className={`text-end text-monospace${
                      false && bonuses.length > 0 ? ' text-primary' : ''
                    }`}
                  >
                    {false && bonuses.length > 0 && (
                      <span className="text-primary">
                        <sup
                          style={{
                            fontWeight: 100,
                            fontSize: '0.4rem',
                            top: '-0.2rem',
                            left: '-0.7rem',
                          }}
                        >
                          ●
                        </sup>
                      </span>
                    )}
                    {user?.email && bonuses.length > 0 && (
                      <div style={{ display: 'inline-block' }}>
                        <div className="pulsating-circle" />
                      </div>
                    )}
                    <CountUpDown
                      value={bet < 10 ? balance : Math.floor(balance)}
                      decimals={bet < 10 ? 1 : 0} //{balance > 1000 ? 0 : 1}
                      animate={changeType.up}
                    />
                  </div>

                  <div className="text-end text-muted lh-1">
                    <small>{t('Balance')}</small>
                  </div>
                </div>
                <div className="col-2">
                  <span
                    className={`text-end forward-symbol${
                      false && bonuses.length > 0 ? ' text-primary' : ''
                    }`}
                    style={{
                      position: 'relative',
                      opacity: 0.7,
                      top: '-3px',
                      left: '-5px',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        //isLoading && <Loader overlay />
      }

      {!isLoading &&
        !price &&
        false &&
        location.pathname.replace(/\/+$/, '') === '/app' && (
          <Loader
            onClick={() => {
              if (!price && lastUpdate - lastPriceUpdate > 4000) {
                navigate('/', { replace: true });
              }
            }}
          />
        )}

      {false && (
        <DebugControl
          text1={`bonus=${userOnboarding.context.emptyBoxesExpiredBet}`}
          text2={`win/spent=${(
            userOnboarding.context.emptyBoxesExpiredBet +
            userOnboarding.context.prize
          ).toFixed(1)}/${(
            userOnboarding.context.boxesWinBet +
            userOnboarding.context.boxesExpiredBet
          ).toFixed(1)}`}
          text3={`RTP=${(
            (100 *
              (userOnboarding.context.prize +
                userOnboarding.context.emptyBoxesExpiredBet)) /
            (userOnboarding.context.boxesWinBet +
              userOnboarding.context.boxesExpiredBet)
          ).toFixed(2)}%`}
          onPause={() => {
            setDebugPausePrice(!debugPausePrice);
          }}
        />
      )}

      {
        <ProgressBar
          show={
            !!price &&
            userOnboarding.context.winsCounter > 0 &&
            ((challengeBonus && challengeBonus.expired > Date.now()) ||
              !user.email)
          }
          position={userOnboarding.context.winsCounter}
          max={userOnboarding.context.winsCounterLimit}
          onClick={() => {
            if (!user.email) {
              navigate('./bonus/no-auth', { replace: true });
            } else {
              navigate('./bonus/challenge', { replace: true });
            }
          }}
        />
      }

      {!!price && !user.email && <SoundControl top={`${centralPoint.y}px`} />}

      {!!price && (
        <BetControl
          bet={bet}
          top={`${centralPoint.y}px`}
          showing={
            !!user.email
            /* !userOnboarding.matches('newcomer') ? true : false */
          }
          onSetBet={(b) => {
            //logger?.event('click', { target: 'bet', new: b, old: bet });
            setBet(b);
            clickSound();
          }}
        />
      )}

      <AnimatedCongrats
        state={animatedCongratsState}
        from={centralPoint}
        to={{ x: centralPoint.x, y: -10 }}
      />

      <AnimatedDot
        repeat={pendingAnimation.current}
        from={centralPoint}
        to={{ x: centralPoint.x, y: -10 }}
        onComplete={onAnimatioCompete}
      />

      <BlurPanel
        height="50vh"
        top={topMenuHeight + terminalSize.height - boxSizePx * boxLines}
      />

      <BackgoundLight height={terminalSize.height} top={topMenuHeight} />

      <div
        style={{
          '--box-appear-offset': `${boxesGridMismatch}px`,
          height: `calc(100% - ${bottomMenuHeight}px - ${topMenuHeight}px)`,
          width: '100%',
        }}
      >
        <ResponsiveContainer
          ref={boxTerminalRef}
          width="100%"
          height="100%"
          minHeight={100}
          onResize={(w, h) => setTerminalSize({ width: w, height: h })}
        >
          <ScatterChart
            style={{ touchAction: 'none' }}
            display={price ? '' : 'none'}
            margin={0}
          >
            <XAxis
              hide={true}
              tickLine={false}
              axisLine={false}
              tick={false}
              type="number"
              dataKey="price"
              domain={([dataMin, dataMax]) => {
                return [priceDomain.min, priceDomain.max];
              }}
              orientation="top"
            />
            <YAxis
              hide={true}
              axisLine={false}
              tickLine={false}
              tick={false}
              reversed={true}
              type="number"
              dataKey="time"
              domain={([dataMin, dataMax]) => {
                return [timeDomain.min, timeDomain.max];
              }}
            />
            <ReferenceLine y={time} className="reference-line" />

            {/*             <ReferenceLine
              y={time - timeHistory + 10}
              className="reference-line"
            />
 */}
            <ReferenceLine
              segment={[
                { x: price, y: time - timeHistory + 10 },
                { x: price, y: time + 150 },
              ]}
              className="reference-dashed-line"
            >
              <Label
                className="label muted"
                offset={15}
                position={'bottom'}
                value={`$${priceFmt(price, 2, 2)}`}
              />

              {!isPwa() && !isNativeApp() && isBoxesGridShown && (
                <Label
                  x={price}
                  y={time}
                  className={`label text-uppercase 
                ${
                  //userOnboarding.matches('newcomer') ? ' visible' : ' hidden'
                  price
                    ? userOnboarding.context.bets === 0 //userOnboarding.matches('newcomer')
                      ? ' visible'
                      : ' visible muted'
                    : ' hidden'
                }        
                `}
                  offset={80}
                  position={'bottom'}
                  value={t('Select a box to make a bet')}
                />
              )}
            </ReferenceLine>

            <ReferenceDot
              x={price - boxSize * 3}
              y={time + 20}
              r={1}
              fill="var(--theme-muted)"
              strokeWidth={0}
            />

            <ReferenceDot
              x={price + boxSize * 3}
              y={time + 20}
              r={1}
              fill="var(--theme-muted)"
              strokeWidth={0}
            />

            <ReferenceLine
              display="none"
              segment={[
                { x: price - boxSize * 3, y: time + 150 },
                { x: price - boxSize * 3, y: time - timeHistory + 10 },
              ]}
              className="reference-dashed-line"
            >
              <Label
                offset={15}
                position={'bottom'}
                value={`-${priceFmt(boxSize * 3, 2, 2)}`}
                stroke={0}
                fill="var(--theme-muted)"
              />
            </ReferenceLine>
            <ReferenceLine
              display="none"
              segment={[
                { x: price + boxSize * 3, y: time + 150 },
                { x: price + boxSize * 3, y: time - timeHistory + 10 },
              ]}
              className="reference-dashed-line"
            >
              <Label
                offset={15}
                position={'bottom'}
                value={`+${priceFmt(boxSize * 3, 2)}`}
                stroke={0}
                fill="var(--theme-muted)"
              />
            </ReferenceLine>
            <ReferenceDot
              className={`dotArea ${
                !boxesState?.context?.lastExecutedTime ||
                Date.now() - boxesState.context?.lastExecutedTime >
                  boxTimeSize * 1.15
                  ? boxesState.context?.getCloser
                    ? 'getCloser'
                    : 'standBy'
                  : ''
              }`}
              x={price}
              y={time}
              r={15 * (boxesState.context?.getCloser * 2 || 1)}
              fill="var(--theme-default)"
              stroke="var(--theme-default)"
              strokeWidth={15 * (boxesState.context?.getCloser * 2 || 1)}
            />

            {boxesState.context.boxes &&
              boxesState.context.boxes.map((box) => {
                return (
                  <ReferenceArea
                    id={box.id}
                    className="box"
                    shape={
                      box.lootBox >= 0 && ( // >1
                        <SuperBox box={box} boxSizePx={box.boxSizePx} />
                      )
                    }
                    key={'box' + box.id}
                    x1={box.x1}
                    x2={box.x2}
                    y1={box.y1}
                    y2={box.y2}
                    stroke="var(--theme-muted)"
                    fill={box.lootBox === 1 && 'var(--theme-primary)'}
                    ifOverflow="visible"
                    strokeOpacity={box.status === boxStatus.executed ? 0 : 0.15}
                    fillOpacity={box.status === boxStatus.executed ? 0 : 0.99}
                  >
                    {box.status !== boxStatus.executed && (
                      <Label
                        fill="var(--theme-default)"
                        position={'center'}
                        value={`${priceFmtThousands(
                          box.prize,
                          box.prize > 10 ? 0 : -1
                        )}`}
                        style={{
                          userSelect: 'none',
                          fontWeight: '600',
                        }}
                      ></Label>
                    )}
                  </ReferenceArea>
                );
              })}
            <Scatter
              data={data}
              line={{ stroke: 'var(--theme-primary-accent)', strokeWidth: 1 }}
              lineJointType=""
              width={0}
              shape={() => {}}
              isAnimationActive={false}
            />
            <ReferenceDot
              x={price}
              y={time}
              r={4}
              fill="var(--theme-primary)"
              stroke="var(--theme-default)"
              strokeWidth={3}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>

      <div
        //className="row g-0"
        style={{
          position: 'absolute',
          zIndex: 10,
          width: '100%',
          //height: 350, //boxSizePx * boxLines,
          bottom: bottomMenuHeight,
        }}
      >
        {!!price &&
          boxesGrid &&
          isBoxesGridShown &&
          boxLinesArray.map((_, lineIdx) => {
            //console.log('boxLinesArray===', boxLinesArray.length);
            return (
              <div
                className="row flex-nowrap"
                key={lineIdx}
                style={{
                  margin: 0,
                  width: '100%',
                }}
              >
                {boxColumnsArray.map((_, columnIdx) => {
                  const i = lineIdx * boxColumns + columnIdx;
                  const box = boxesGrid[i];

                  const size = `${boxSizePx}px`;
                  const isSelected =
                    boxSelectionState?.context.selection?.findIndex(
                      (b) => b.id === box.id
                    ) >= 0;
                  return (
                    <div //x1={box.x1} x2={box.x2} y1={box.y1} y2={box.y2}
                      style={{
                        width: size,
                        height: size,
                      }}
                      className={`col grid-box glow ${
                        box.isPrizeUpdated ? 'updated' : ''
                      }${isSelected ? ' selected' : ''}${
                        box.prize < 1 ? ' disabled' : ''
                      }${
                        // <= 1
                        isSelected && balance < bet ? ' invalid' : ''
                      }`}
                      key={'gridBox' + i}
                      box_id={i}
                      onMouseDown={(e) => {
                        if (!price || isMobile) return;
                        boxSelectionSend({ type: 'RESET' });
                        addSelectedBox(box);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onMouseMove={(e) => {
                        if (!price || isMobile) return;
                        if (e.buttons === 1 || e.buttons === 3)
                          addSelectedBox(box);
                      }}
                      onMouseUp={(e) => {
                        if (isMobile) return;
                        if (price) {
                          boxSelectionSend({ type: 'SUBMIT' });
                        } else boxSelectionSend({ type: 'RESET' });
                      }}
                      //isMobile
                      onTouchStart={(e) => {
                        if (!price || !isMobile) return;
                        boxSelectionSend({ type: 'RESET' });
                        addSelectedBox(box);
                        e.stopPropagation();
                      }}
                      onTouchMove={(e) => {
                        if (!price || !isMobile) return;
                        const boxId = document
                          .elementFromPoint(
                            e.changedTouches[0]?.clientX,
                            e.changedTouches[0]?.clientY
                          )
                          ?.getAttribute('box_id');
                        const boxOver = boxesGrid.find(
                          (b) => `${b.id}` === `${boxId}`
                        );
                        addSelectedBox(boxOver);
                      }}
                      onTouchEnd={(e) => {
                        if (!isMobile) return;
                        const boxId = document
                          .elementFromPoint(
                            e.changedTouches[0]?.clientX,
                            e.changedTouches[0]?.clientY
                          )
                          ?.getAttribute('box_id');
                        const boxOver = boxesGrid.find(
                          (b) => `${b.id}` === `${boxId}`
                        );
                        if (price && boxOver) {
                          boxSelectionSend({ type: 'SUBMIT' });
                        } else boxSelectionSend({ type: 'RESET' });
                      }}
                    >
                      {`${priceFmt(box.prize, 1, 1)}`}
                      {
                        // `× ${priceFmt(box.prize)}`
                        // bet >= 1000
                        //  ? `${priceFmtThousands(box.prize * bet, bet < 10000 ? 1 : 0)}`
                        //  : `${priceFmt(box.prize * bet, bet < 10 ? 1 : 0)}`
                        // {box.prize === 1 ? '× 1.' : `× ${priceFmt(box.prize)}`
                        // {box.prize === 1 && <span></span>}
                      }
                    </div>
                  );
                })}
              </div>
            );
          })}

        {/*         {!!price &&
          boxesGrid &&
          isBoxesGridShown &&
          boxesGrid.map((box, i) => {
            const size = `${boxSizePx}px`;
            const isSelected =
              boxSelectionState?.context.selection?.findIndex(
                (b) => b.id === box.id
              ) >= 0;
            return (
              <div //x1={box.x1} x2={box.x2} y1={box.y1} y2={box.y2}
                style={{
                  width: size,
                  height: size,
                }}
                className={`grid-box glow ${
                  box.isPrizeUpdated ? 'updated' : ''
                }${isSelected ? ' selected' : ''}${
                  box.prize < 1 ? ' disabled' : ''
                }${
                  // <= 1
                  isSelected && balance < bet ? ' invalid' : ''
                }`}
                key={'gridBox' + i}
                box_id={i}
                onMouseDown={(e) => {
                  if (!price || isMobile) return;
                  boxSelectionSend({ type: 'RESET' });
                  addSelectedBox(box);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onMouseMove={(e) => {
                  if (!price || isMobile) return;
                  if (e.buttons === 1 || e.buttons === 3) addSelectedBox(box);
                }}
                onMouseUp={(e) => {
                  if (isMobile) return;
                  if (price) {
                    boxSelectionSend({ type: 'SUBMIT' });
                  } else boxSelectionSend({ type: 'RESET' });
                }}
                //isMobile
                onTouchStart={(e) => {
                  if (!price || !isMobile) return;
                  boxSelectionSend({ type: 'RESET' });
                  addSelectedBox(box);
                  e.stopPropagation();
                }}
                onTouchMove={(e) => {
                  if (!price || !isMobile) return;
                  const boxId = document
                    .elementFromPoint(
                      e.changedTouches[0]?.clientX,
                      e.changedTouches[0]?.clientY
                    )
                    ?.getAttribute('box_id');
                  const boxOver = boxesGrid.find(
                    (b) => `${b.id}` === `${boxId}`
                  );
                  addSelectedBox(boxOver);
                }}
                onTouchEnd={(e) => {
                  if (!isMobile) return;
                  const boxId = document
                    .elementFromPoint(
                      e.changedTouches[0]?.clientX,
                      e.changedTouches[0]?.clientY
                    )
                    ?.getAttribute('box_id');
                  const boxOver = boxesGrid.find(
                    (b) => `${b.id}` === `${boxId}`
                  );
                  if (price && boxOver) {
                    boxSelectionSend({ type: 'SUBMIT' });
                  } else boxSelectionSend({ type: 'RESET' });
                }}
              >
                {`${priceFmt(box.prize, 1, 1)}`}
                {
                  // `× ${priceFmt(box.prize)}`
                  // bet >= 1000
                  //  ? `${priceFmtThousands(box.prize * bet, bet < 10000 ? 1 : 0)}`
                  //  : `${priceFmt(box.prize * bet, bet < 10 ? 1 : 0)}` 
                  // {box.prize === 1 ? '× 1.' : `× ${priceFmt(box.prize)}`
                  // {box.prize === 1 && <span></span>}
                }
              </div>
            );
          })} 
          */}
      </div>

      <div
        className="container pt-3 pb-3 text-center"
        style={{
          position: 'absolute',
          zIndex: 10,
          top: topMenuHeight + terminalSize.height,
          height: bottomMenuHeight,
          width: '100%',
        }}
      >
        {!!price && (isPwa() || isNativeApp()) && (
          <small
            className={`label visible muted
          ${
            userOnboarding.context.bets === 0 ? '' : ' muted' //userOnboarding.matches('newcomer')
          }
          `}
            style={{
              position: isNativeApp() || isPwa() ? '' : 'absolute',
              top: `${
                terminalSize.height + topMenuHeight - boxLines * boxSizePx
              }px`,
              transform: 'translate(-50%, -130%)',
              left: '50%',
              textWrap: 'nowrap',
            }}
          >
            {t('Select a box to make a bet')}
          </small>
        )}
      </div>
    </>
  );
}

function updateData(data, newData, timeHistory, setData) {
  if (!newData || !newData.length) {
    return;
  }
  let updatedData = data;
  const isCurrent =
    !data.length || newData[0].time >= data[data.length - 1].time;
  const isRecent =
    !isCurrent && newData[newData.length - 1].time <= data[0].time;
  if (isCurrent) {
    updatedData = [...updatedData, ...newData];
  } else if (isRecent) {
    updatedData = [...newData, ...updatedData];
  }
  let i = 0;
  const minTime = updatedData[updatedData.length - 1].time - timeHistory - 1000;
  while (i < updatedData.length && updatedData[i].time < minTime) {
    i += 2;
  }
  updatedData = updatedData.slice(i);
  setData(updatedData);
}
