import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../shared/layout';

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div style={{ zIndex: 1000, position: 'fixed' }}>
      <Layout>
        <div className="modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <br />
              <div className="modal-header">
                <h1 className="text-center text-default">404</h1>
                <div className="subtitle text-muted text-center">
                  {t('Oops...')}
                </div>
              </div>

              <div className="modal-body">
                <div
                  className="text-center"
                  style={{
                    left: '50%',
                    width: '100%',
                  }}
                >
                  <img
                    src="/img/failed.svg"
                    alt=""
                    style={{
                      marginTop: '4vh',
                      marginBottom: '4px',
                    }}
                  />

                  <div
                    className="invalid-feedback text-center"
                    style={{ padding: '0 40px', fontWeight: 300 }}
                  >
                    {t('Page Not Found')}
                  </div>
                </div>
              </div>
              <div className="modal-footer no--mobile-keyboard justify-content-center">
                <div
                  style={{ margin: '4vh' }}
                  className="btn btn-primary"
                  role="button"
                  onClick={() => {
                    navigate('/', { replace: true });
                  }}
                >
                  {t('Home')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
