import { t } from 'i18next';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, LeagueIcon } from '../components/icons';
import { SelectionList } from '../components/terminal';
import { getUserLeaderboard } from '../shared/api/profile';
import { useLocalStorage } from '../shared/hooks/use-hooks';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { useSoundContext } from '../shared/hooks/use-sound';
import { useUserContext } from '../shared/hooks/use-user';
import { ModalLayout } from '../shared/layout';
import { getEndOfWeek, parseMillisecondsLeft, priceFmt } from '../shared/utils';
import { weekNumber } from 'weeknumber';

const getLeagues = () => [
  { name: t('Emerald League'), color: 'var(--theme-emerald-dark)' },
  { name: t('Bronze League'), color: '#B2946E' },
  { name: t('Silver League'), color: '#A0A0A0' },
  { name: t('Golden League'), color: '#AF8C0B' },
  { name: t('Crystal League'), color: 'var(--theme-crystal-dark)' },
  { name: t('Ruby League'), color: '#F24E1E' },
  { name: t('Amethyst League'), color: '#8888D7' },
  { name: t('VIP League'), color: '#8888D7', isLocked: true },
  { name: t('VIP League'), color: '#8888D7', isLocked: true },
  { name: t('VIP League'), color: '#8888D7', isLocked: true },
];

export function LeagueStatus({}) {
  const logger = useLoggerContext();
  const leagues = getLeagues();
  const { initUser, user } = useUserContext();
  const [league, setLeague] = useState(null);
  const [lastSeenLeague, setLastSeenLeague] = useLocalStorage(
    'lastSeenLeague',
    0
  );
  const [lastSeenLeagueDate, setLastSeenLeagueDate] = useLocalStorage(
    'lastSeenLeagueDate',
    null
  );

  const [leagueChange, setLeagueChange] = useState();
  const { levelUp } = useSoundContext();

  useEffect(() => {
    const fetchData = async () => {
      const u = await initUser(user?.email);
      setLeague(u?.league);
      const newLeagueChange = u?.league - lastSeenLeague;
      setLeagueChange(newLeagueChange);

      const isNewUser = u?.league === 0 && lastSeenLeagueDate === null;

      const nowWeek = weekNumber(new Date());
      const lastSeenWeek = lastSeenLeagueDate
        ? weekNumber(new Date(lastSeenLeagueDate))
        : nowWeek;
      const weekPassed = nowWeek > lastSeenWeek;

      if (
        !isNewUser &&
        (newLeagueChange !== 0 || (newLeagueChange === 0 && weekPassed))
      ) {
        levelUp();
        setLastSeenLeagueDate(Date.now());
      } else {
        navigate('/app/leaderboard', { replace: true });
      }
    };
    fetchData();
  }, []);

  const navigate = useNavigate();
  const onClose = () => {
    logger?.event('league', {
      change: leagueChange,
      league,
    });

    setLastSeenLeague(league);
    navigate('/app/leaderboard', { replace: true });
  };

  return (
    <>
      {league !== null && (
        <ModalLayout hideNavigation>
          <div
            className="modal-header text-center"
            style={{ marginTop: '25px' }}
          >
            {leagueChange > 0 && (
              <>
                <h1>{t('Congrats!')}</h1>
                <div>{t(`You are advanced to the next league`)}</div>
              </>
            )}

            {leagueChange === 0 && (
              <>
                <h1>{league > 0 ? t('Good job!') : t('You can do better!')}</h1>
                <div>{t(`You kept your position in the league`)}</div>
              </>
            )}

            {leagueChange < 0 && (
              <>
                <h1>{t('Better luck next time!')}</h1>
                <div>{t(`You are dropped down to the previous league`)}</div>
              </>
            )}
          </div>
          <div className="modal-body">
            <div
              className="text-center"
              style={{
                position: 'absolute',
                left: '50%',
                top: '30vh',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <LeagueIcon
                color={leagues[league]?.color}
                style={{
                  display: 'inline-block',
                  width: `35vw`,
                  height: `35vw`,
                }}
              />
              <h2 className="text-center">{leagues[league]?.name}</h2>
            </div>
          </div>
          <div className="modal-footer no-mobile-keyboard">
            <div
              className={`btn btn-primary`}
              role="button"
              onClick={async () => {
                onClose();
              }}
            >
              {t(`Continue`)}
            </div>
          </div>
        </ModalLayout>
      )}
    </>
  );
}

export function Leaderboard({ email, winBalance }) {
  const leagues = getLeagues();
  const [league] = useLocalStorage('league', 0);
  const [nickname] = useLocalStorage('nickname', 0);
  const [leaderboard, setLeaderboard] = useState([]);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState({});
  const [place, setPlace] = useState(-1);
  const [lastSeenPlace, setLastSeenPlace] = useLocalStorage(
    'lastSeenPlace',
    29
  );
  const [placeChange, setPlaceChange] = useState(0);

  const navigate = useNavigate();
  const onClose = () => {
    navigate('/app', { replace: true });
  };

  const logger = useLoggerContext();

  useEffect(() => {
    async function fetch() {
      const newData = await getUserLeaderboard();

      const newPlace =
        newData?.length === 0
          ? 0
          : newData?.findIndex((u) => u?.user_name === email);
      if (newPlace >= 0) {
        setUserData(newData[newPlace]);
        setPlace(newPlace);
        const newPlaceChange = lastSeenPlace - newPlace;
        setPlaceChange(newPlaceChange);
        logger.event('leaderboard', {
          change: newPlaceChange,
          place: newPlace + 1,
          league,
        });
        setData([
          ...newData?.slice(0, newPlace),
          ...newData?.slice(newPlace + 1),
        ]);
      }
    }
    fetch();
  }, []);

  const { hits } = useSoundContext();

  useEffect(() => {
    if (place < 0) return;
    const newLeaderboard = [
      ...data.slice(0, lastSeenPlace),
      userData,
      ...data.slice(lastSeenPlace),
    ];
    setLeaderboard(newLeaderboard);

    if (lastSeenPlace !== place) {
      const maxInterval = 400;
      const minInterval = 50;
      const diff = Math.abs(place - lastSeenPlace);
      const maxDiff = 30;
      const interval =
        (1 - diff / maxDiff) ** 4 * (maxInterval - minInterval) + minInterval; //Delay is higher for smaller diff
      setTimeout(() => {
        setLastSeenPlace(
          lastSeenPlace < place ? lastSeenPlace + 1 : lastSeenPlace - 1
        );
        hits(3);
      }, interval);
    }
  }, [data?.length, place, lastSeenPlace]);

  const [activeLeagueIdx, setActiveLeagueIdx] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      scrollerRef?.current?.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
      setActiveLeagueIdx(league);
    }, 700);
  }, [activeLeagueIdx]);

  const inactiveSize = 40;
  const activeSize = 60;
  const leftPos = -(activeLeagueIdx * (inactiveSize + 10 + 1) + activeSize / 2);

  const { days, hours, minutes, seconds } = parseMillisecondsLeft(
    getEndOfWeek() - Date.now()
  );
  const timeLeft =
    days > 1 ? days + 1 : `${Number(hours) + days * 24}:${minutes}:${seconds}`;
  const timeLeftCaption = days > 1 ? t(`Days left`) : t(`Time left`);
  const scrollerRef = useRef();

  return (
    <>
      <ModalLayout hideNavigation>
        <div className="modal-header text-center" style={{ marginTop: '25px' }}>
          <div
            style={{
              height: `${activeSize}px`,
              overflowY: 'hidden',
              scrollbarWidth: 'none',
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflowX: 'scroll',
                scrollbarWidth: 'none',
                height: `${activeSize + 10}px`,
              }}
              ref={scrollerRef}
            >
              <div
                style={{
                  position: 'relative',
                  transform: 'translateX(50%)',
                  left: `${leftPos}px`,
                  transition: 'left 0.2s ease-in',
                  height: `${activeSize}px`,
                }}
              >
                {leagues?.length > 0 &&
                  leagues.map((lg, idx) => {
                    const size =
                      idx === activeLeagueIdx //&& !lg.isLocked
                        ? `${activeSize}px`
                        : `${inactiveSize}px`;
                    return (
                      <LeagueIcon
                        isLocked={lg.isLocked}
                        key={idx}
                        color={lg.color}
                        style={{
                          width: `${size}`,
                          height: `${size}`,
                          margin: '0 5px',
                        }}
                        onClick={() => {
                          setTimeout(() => {}, 500);
                          !lg.isLocked && setActiveLeagueIdx(idx);
                        }}
                      />
                    );
                  })}
              </div>
            </div>
          </div>

          <h1>{leagues[activeLeagueIdx]?.name}</h1>

          <div className="row" style={{ margin: '20px 0 16px' }}>
            <div className="col-4">
              <div className="text-center text-default">{timeLeft}</div>
              <div className="text-center text-muted lh-1">
                <small>{timeLeftCaption}</small>
              </div>
            </div>

            <div className="col-4">
              <div className="text-center text-primary-accent">
                {priceFmt(winBalance, 0)}
              </div>
              <div className="text-center text-muted lh-1">
                <small>{t('Today')}</small>
              </div>
            </div>

            <div className="col-4">
              <div className="text-center text-default">
                <span>{place >= 0 ? place + 1 : '-'} </span>
                {placeChange > 0 && (
                  <small className="text-primary d-inline-block align-text-bottom">{` ▴${placeChange}`}</small>
                )}
                {placeChange < 0 && (
                  <small className="text-red d-inline-block align-text-bottom">{` ▾${Math.abs(
                    placeChange
                  )}`}</small>
                )}
              </div>
              <div className="text-center text-muted lh-1">
                <small>{t('Place')}</small>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div>
            {leaderboard && (
              <SelectionList
                height="49dvh"
                activeItemIdx={lastSeenPlace}
                items={leaderboard.map((u, idx) => {
                  const userName =
                    u?.user_name === email
                      ? !!nickname
                        ? nickname
                        : email?.split('@').shift()
                      : u?.user_name?.replace('www.', '') || '';
                  const result = {
                    name: (
                      <>
                        <span
                          style={{ display: 'inline-block', width: '15px' }}
                        >
                          {idx + 1}{' '}
                        </span>
                        <Avatar
                          username={u?.user_name === email ? email : userName}
                          style={{
                            height: '35px',
                            margin: '-10px 10px',
                          }}
                        />
                        <span
                          style={{
                            maxWidth: '40vw',
                            textTransform: 'capitalize',
                            display: 'inline-block',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {userName}
                        </span>
                      </>
                    ),
                    value: (
                      <div className="text-monospace">{`${
                        u.score ? priceFmt(u.score, 0) : 'n/a'
                      }`}</div>
                    ),
                    type: 'static',
                    isActive: userName === nickname,
                  };
                  return result;
                })}
                onClick={(idx) => {}}
              />
            )}
          </div>
        </div>
        <div className="modal-footer no-mobile-keyboard">
          <div
            className={`btn btn-primary`}
            role="button"
            onClick={async () => {
              onClose();
            }}
          >
            {t(`Close`)}
          </div>
        </div>
      </ModalLayout>
    </>
  );
}
