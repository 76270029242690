import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Subscription from './pages/subscription';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { register, update } from './serviceWorkerRegistration';
import { UserContext } from './shared/hooks/use-user';
import { LoggerContext } from './shared/hooks/use-logger';
import { UpdaterContext } from './shared/hooks/use-updater';
import { BonusesContext } from './shared/hooks/use-bonus';
import { AdsenseContext } from './shared/hooks/use-adsense';
import { MonetagContext } from './shared/hooks/use-monetag';
import { NativeAppContext } from './shared/hooks/use-native-app';

i18next.use(LanguageDetector).init({
  resources: {
    en: {
      translation: require('./locales/en.json'),
    },
    es: {
      translation: require('./locales/es.json'),
    },
    de: {
      translation: require('./locales/de.json'),
    },
    tr: {
      translation: require('./locales/tr.json'),
    },
    ru: {
      translation: require('./locales/ru.json'),
    },
  },
});

document.documentElement.lang = i18next.language;

const router = createBrowserRouter([
  {
    path: 'subscription',
    element: <Subscription />,
  },
  {
    path: 'link',
    element: (
      <>
        {process.env.NODE_ENV === 'production' ? (
          <div />
        ) : (
          <div className="text-default">
            <div className="m-3">
              <a target="_blank" href="https://boxo.trade/app/bonus/">
                https://boxo.trade/app/bonus/
              </a>
            </div>
            <div className="m-3">
              <a href="boxo://app">boxo://app</a>
            </div>
            <div className="m-3">
              <a href="trade.boxo://app">trade.boxo://app</a>
            </div>
            <div className="m-3">
              <a href="exp://172.20.10.8:8082">exp://172.20.10.8:8082</a>
            </div>
          </div>
        )}
      </>
    ),
  },
  {
    path: 'subscription/:email',
    element: <Subscription />,
  },
  {
    path: '*',
    element: (
      <LoggerContext>
        <UpdaterContext>
          <NativeAppContext>
            <AdsenseContext>
              <MonetagContext>
                <UserContext>
                  <BonusesContext>
                    <App />
                  </BonusesContext>
                </UserContext>
              </MonetagContext>
            </AdsenseContext>
          </NativeAppContext>
        </UpdaterContext>
      </LoggerContext>
    ),
  },
]);

if (process.env.NODE_ENV === 'production') console.log = function () {};

const root = ReactDOM.createRoot(document.getElementById('root'));

register();

update();

root.render(<RouterProvider router={router} />);
